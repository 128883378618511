#hwx-default-category {
    .hwx-itemlist-description{
        @include rem(margin-bottom, 50);
    }
    
    .hwx-default-category-body {
        
        & > div:not(:last-child) {
            .hwx-default-category-item-container {
                .card {
                    border-bottom : 0;
                }
            }
        }
        
        .hwx-default-category-item-container {
            .accordion {
                .card {
                    border : 1px solid rgba($black, 0.125);
                }
                
                .hwx-itemlist-header {
                    padding : 0;
                    
                    .hwx-itemlist-header-text {
                        line-height : 0;
                        
                        button {
                            @include rem(font-size, 24);
                            @include rem(padding, 15);
                            width      : 100%;
                            text-align : left;
                        }
                    }
                }
            }
        }
    }
}