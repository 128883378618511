$gradient-dark       : #43abe8;
$gradient-light      : #ffffff;
$green               : #6d9c00;
$green-light         : #d9e4bc;
$blue                : rgb(39, 65, 115);
$grey-light          : #cdcdcd;

$transition-collapse : height .8s;

$container-max-widths: (
        //sm: 540px,
        //md: 720px,
        lg: 960px,
        xl: 1140px
);