footer#footer {
    @include make-sticky;
    @include zindex(footer);
    @include rem-multivalue(margin, $footer-margin-top, $footer-margin-right, $footer-margin-bottom, $footer-margin-left);
    @include rem-multivalue(padding, $footer-padding-top, $footer-padding-right, $footer-padding-bottom, $footer-padding-left);
    background-color : $footer-background-color;

    //div.footer-navigation {
    //    nav.navbar {
    //        a.nav-link {
    //            @include footer-nav-font;
    //        }
    //    }
    //}
}