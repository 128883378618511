.hwx-item-imagetitleblock,
.hwx-itemlist-imagetitleblock {
    @include rem(margin-bottom, 50);
    
    .hwx-as-background {
        @include rem(height, 450);
        background-position : center;
        background-repeat   : no-repeat;
    }
    
    .hwx-item-title {
        .hwx-item-image-text {
            padding : 0.5rem 3rem;
        }
    }
}