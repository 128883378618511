nav.navbar {
    > div {
        position : relative;

        a.nav-link {
            &.home-icon {
                @include rem(font-size, $hwx-menu-home-icon-font-size);
            }
        }

        ul.navbar-nav {
            span.sub-arrow {
                border-color : $hwx-menu-sub-menu-arrow-color;
            }

            ul {
                border           : none;
                border-radius    : 0;

                // Mehrspaltigkeit in Subemenüs
                &[class^="make-nav-cols-"],
                &[class*=" make-nav-cols-"] {
                    @include rem(width, $hwx-menu-multicol-ul-width);

                    .dropdown-item {
                        display         : flex;
                        justify-content : center;
                        align-items     : center;
                    }
                }
            }

            li {
                a.nav-link {
                    @include make-link-border;
                }
            }
        }
    }
}