@import 'modules/hex';
@import 'modules/rsform';
@import 'modules/modal';
@import 'modules/hwxBanner';
@import 'layouts/itemTitleImage';
@import 'components/articles/default';
@import 'components/articles/partner';
@import 'components/kategorien/projekte';
@import 'components/kategorien/default';
@import 'common';
@import 'header';
@import 'main';
@import 'footer';

div.main-content-container {
    div.itemImageHeader {
        height              : 450px;
        background-position : center;
        background-repeat   : no-repeat;
        background-size     : cover;
        display             : flex;
        align-items         : center;
        justify-content     : center;
        margin-bottom       : 4rem;
        
        div.headerInfo {
            display          : flex;
            color            : #ffffff;
            background-color : rgba(0, 0, 0, 0.6);
            align-items      : center;
            justify-content  : center;
            padding          : 0.5rem 3rem 0.5rem 0;
            
            &.partner {
                padding : 0.5rem 3rem 0.5rem 3rem;
            }
            
            h1 {
                margin : 0;
            }
        }
    }
    
    div.itemHeader {
        h1 {
            color         : $green;
            font-size     : 1.3rem;
            font-weight   : 900;
            margin-bottom : 1.5rem;
        }
    }
    
    div.itemText {
        margin-bottom : 3rem;
        
        div.itemFullText {
        
        }
    }
    
    div.itemInfos {
        div.itemExtraFields {
            h3 {
                letter-spacing : 0.3px;
                font-size      : 1rem;
                font-weight    : 700;
                color          : $grey-light;
            }
            
            font-size : 0.825rem;
            
        }
        
        div.osmMap {
            @include media-breakpoint-up(md) {
                margin-top : 32px;
            }
        }
        
        div.itemImageGallery {
            h3 {
                letter-spacing : 0.3px;
                font-size      : 1rem;
                font-weight    : 700;
                color          : $grey-light;
            }
        }
    }
    
    div.itemList {
        div.row {
            div.card {
                @include rem(margin-bottom, 50);
                border : none;
                
                a {
                    color : #274173;
                }
                
                div.card-header {
                    @include rem(margin-bottom, 15);
                    padding          : 0;
                    border           : none;
                    border-radius    : 0;
                    background-color : transparent;
                    
                    .card-header-text {
                        margin : 0;
                    }
                }
                
                div.card-body {
                    padding : 1rem 0 1.25rem 0;
                    
                    h5 {
                        margin-bottom : 0.275rem;
                        color         : $green;
                        font-weight   : 900;
                    }
                    
                }
                
                > :hover {
                    text-decoration : none;
                }
            }
        }
    }
}