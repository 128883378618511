#hwx-modal-wabe {
    .modal-dialog {
        @include rem(max-width, 650);
        
        .modal-header {
            border-bottom : none;
            position      : relative;
            
            .modal-title {
                width      : 100%;
                text-align : center;
            }
            
            .close {
                @include rem(top, 15);
                @include rem(right, 15);
                position : absolute;
            }
        }
        
        .modal-body {
            @include rem(padding, 30);
            
            @include media-breakpoint-up(sm) {
                max-width : 75%;
                margin    : 0 auto;
            }
            
            .hwx-modal-wabe-image {
                @include rem(margin-bottom, 30);
            }
            
            .hwx-modal-wabe-text {
                p:last-of-type {
                    margin : 0;
                }
            }
        }
    }
}