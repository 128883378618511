main#content {
    @include media-breakpoint-up(md) {
        margin-top : 0 !important;
    }
    
    .content-main-background {
        .main-content-container {
        }
    }
    
    .content-bottom-background {
        #content-bottom {
            .content-bottom-container {
            }
        }
    }
}