footer#footer {
    .footer-top {
        @include rem(margin-top, 100);

        img {
            width: 100%;
        }
    }

    .footer-main {
        @include rem(padding-top, 40);
        @include rem(padding-bottom, 40);
        background: $green;
        color: $white;

        a {
            color: $white;

            @include hover-focus {
                color: darken($white, 10%);
            }
        }

        .hwx-address-footer,
        .hwx-contact-footer {
            p {
                &:first-child {
                    text-transform: uppercase;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }


    }

    .footer-nav-bottom {
        background: $green;

        nav.hwx-sub-menu-foot-nav {
            padding: 0;

            a {
                font-size: 0.9rem;
                text-transform: uppercase;
                color: $white;
                letter-spacing: 0.075rem;

                @include media-breakpoint-up(sm) {
                    @include rem-multivalue(padding, 8, 16);
                    @include rem(margin-left, 20);
                    @include rem(margin-right, 20);
                }
            }
        }

        .hwx-copyright-footer {
            text-align: right;
            color: $white;
            font-size: 0.8rem;
            margin-bottom: 0.275rem;

        }
    }
}