#hwx-default-item {
}

body.kontakt {
    #hwx-default-item {
        .hwx-item-introtext {
            .kontakt-infos {
                @include make-vertical-spacing(15);
                display   : flex;
                flex-flow : row wrap;
                
                @include media-breakpoint-down(sm) {
                    @include rem(margin-bottom, 30);
                }
                
                .kontakt-info-icon,
                .kontakt-info-text {
                    display         : flex;
                    align-items     : flex-start;
                    justify-content : center;
                    flex-direction  : column;
                }
                
                .kontakt-info-icon {
                    @include rem(font-size, 30);
                    flex-basis : 15%;
                    color      : $blue;
                }
                
                .kontakt-info-text {
                    flex-basis : 85%;
                }
            }
        }
    }
}