header#header {
    box-shadow : 0 1px 5px 1px rgba($black, 0.3);
    position   : relative;
    
    &:not(.hwx-container-initialheight) {
        nav.hwx-main-menu-head-nav {
            .container {
                @include rem(height, $initial-nav-height);
            }
            
            div.navbar-collapse {
                @include media-breakpoint-down(md) {
                    @include rem(top, $initial-nav-height);
                    transition : top, 0.2s;
                }
            }
        }
    }
    
    .header-nav-bottom-background {
        background-color : rgba($white, 0.9);
        position         : absolute;
        top              : 0;
        left             : 0;
        right            : 0;
        bottom           : 0;
    }
    
    nav.hwx-main-menu-head-nav {
        $navbar-margin-x : 50;
        padding-top      : 0;
        padding-bottom   : 0;
        
        .container {
            @include rem(height, $nav-height);
            transition : height, 0.2s;
            padding    : 0;
        }
        
        a.navbar-brand {
            padding : 0;
            @include rem(margin-left, $navbar-margin-x / 2);
            
            @include media-breakpoint-up(sm) {
                @include rem(margin-left, $navbar-margin-x);
            }
            
            img {
                height : 100%;
            }
        }
        
        div.button-container {
            @include rem(margin-right, $navbar-margin-x / 2);
            
            @include media-breakpoint-between(sm, md) {
                @include rem(margin-right, $navbar-margin-x);
            }
        }
        
        div.navbar-collapse {
            @include media-breakpoint-down(md) {
                @include rem(top, $nav-height);
                position         : absolute;
                left             : 0;
                right            : 0;
                box-shadow       : 0 6px 5px 1px rgba($black, 0.3);
                background-color : rgba($white, 0.9);
                transition       : top, 0.2s;
            }
            
            @include media-breakpoint-up(lg) {
                @include rem(margin-right, $navbar-margin-x);
            }
            
            ul#hwx-main-menu-head {
                @include media-breakpoint-down(md) {
                    @include rem(margin-bottom, 15);
                }
                
                & > li {
                    @include media-breakpoint-down(md) {
                        @include rem(padding-top, 5);
                        @include rem(padding-bottom, 5);
                    }
                    
                    @include media-breakpoint-up(xl) {
                        @include rem(margin-left, 10);
                        @include rem(margin-right, 10);
                    }
                    
                    &.active,
                    &:hover,
                    &:focus {
                        a.nav-link {
                            span {
                                @include rem(padding-bottom, 5);
                                border-bottom : 2px solid $primary;
                            }
                        }
                    }
                    
                    a.nav-link {
                        line-height     : 1.2;
                        justify-content : center;
                        color           : $primary;
                        
                        @include media-breakpoint-down(md) {
                            br {
                                @include rem(padding-left, 2);
                                @include rem(padding-right, 2);
                                content : '';
                            }
                        }
                    }
                }
            }
        }
    }
}