.hwx-banner {
    .carousel-item {
        .carousel-caption {
            top             : 0;
            right           : 0;
            left            : 0;
            bottom          : 0;
            padding         : 0;
            display         : flex;
            justify-content : center;
            align-items     : center;
            
            @include media-breakpoint-up(md) {
                @include rem(top, $initial-nav-height);
            }
            
            .carousel-caption-content {
                @include rem-multivalue(padding, 10, 20);
                @include rem(font-size, 20);
                font-weight      : $font-weight-bold;
                background-color : rgba($green, 0.8);
                color            : $white;
                margin           : 0;
                
                @include media-breakpoint-up(sm) {
                    @include rem(font-size, 28);
                }
            }
        }
    }
    
    &.hwx-carousel-blurred {
        .hwx-blurred-image-container {
            @include rem(bottom, 25);
            position          : absolute;
            top               : 0;
            left              : 0;
            right             : 0;
            z-index           : 0;
            overflow          : hidden;
            -webkit-transform : translate3d(0, 0, 0);
            
            .hwx-blurred-image {
                filter : blur(10px);
                height : 100%;
            }
        }
        
        .carousel-image {
            position : relative;
            z-index  : 1;
        }
    }
}