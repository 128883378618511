// Colors
.make-background-black {
    background-color : $black !important;
}

.make-foreground-black {
    color : $black !important;
}

.make-border-gray {
    border-color : $gray-500 !important;
}

.make-border-black {
    border-color : $black !important;
}

@each $color, $value in $colors {
    .make-background-#{$color} {
        background-color : #{$value} !important;
    }

    .make-foreground-#{$color} {
        color : #{$value} !important;
    }

    .make-border-#{$color} {
        border-color : #{$value} !important;
    }
}

@each $color, $value in $grays {
    .make-background-gray-#{$color} {
        background-color : #{$value} !important;
    }

    .make-foreground-gray-#{$color} {
        color : #{$value} !important;
    }

    .make-border-#{$color} {
        border-color : #{$value} !important;
    }
}

// ul behaviours
.make-lis-eq-width {
    ul {
        flex-grow : 1;
    }

    li {
        flex : 1 1 auto;
    }
}

ul[class^="make-nav-cols-"],
ul[class*=" make-nav-cols-"] {
    flex-wrap : wrap;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix : breakpoint-infix($breakpoint);
    
    @include media-breakpoint-up($breakpoint) {
        @for $i from 1 through $grid-columns {
            .make-nav-cols#{$infix}-#{$i} {
                > li {
                    flex      : 0 0 percentage($i / $grid-columns);
                    max-width : percentage($i / $grid-columns);
                }
            }
        }
    }
}