body.projektuebersicht {
    // Doof aber auf die Schnelle nicht anders möglich. Dient zum Ausblenden des Banners in allen Unterkategorien
    &:not([data-id=""]),
    &:not([data-view="itemlist"]) {
        #content-banner-top {
            display : none;
        }
    }
    
    nav.hwx-main-menu-head-nav {
        a.projekte {
            span {
                @include rem(padding-bottom, 5);
                border-bottom : 2px solid $primary;
            }
        }
    }
    
    .hwx-projektitem-header,
    .hwx-projektitem {
        @include rem-multivalue(padding, 8);
    }
    
    .hwx-projektitem-header {
        background-color : $gray-200;
        border           : 1px solid $gray-300;
        
        .hwx-projektitem-header-text {
            margin-bottom : 0;
        }
    }
    
    .hwx-projektitem {
        @include rem(margin-bottom, 20);
    }
    
    div.hwx-card-item {
        @include rem-multivalue(padding, 15);
        
        padding       : 0;
        border        : none;
        border-radius : 0;
        
        div.hwx-card-content {
            position   : relative;
            min-height : 225px;
            
            &:hover {
                cursor        : pointer;
                border        : 1px solid rgba($black, 0.125);
                border-radius : .25rem;
                
                .card-preview {
                    opacity    : 0;
                    transition : opacity 800ms ease-in-out;
                }
                
                .card-introtext {
                    opacity    : 1;
                    transition : opacity 800ms ease-in-out;
                }
                
            }
            
            .card-preview {
                position         : absolute;
                z-index          : 1;
                background-color : #ffffff;
                top              : 0;
                left             : 0;
                right            : 0;
                bottom           : 0;
                opacity          : 1;
                transition       : opacity 800ms ease-in-out;
                display          : flex;
                flex-direction   : column;
                justify-content  : center;
                overflow         : hidden;
                
                div.hwx-card-img-top {
                    text-align : center;
                }
                
                div.hwx-card-header {
                    position         : absolute;
                    left             : 0;
                    right            : 0;
                    bottom           : 0;
                    height           : 3.25rem;
                    background-color : rgba(109, 156, 0, 0.7);
                    display          : flex;
                    align-items      : center;
                    
                    h4.hwx-card-title {
                        width      : 100%;
                        color      : #ffffff;
                        font-size  : 1rem;
                        text-align : center;
                        margin     : 0;
                    }
                }
            }
            
            .card-introtext {
                @include rem-multivalue(padding, 15);
                @include rem-multivalue(margin, 15);
                
                position         : absolute;
                z-index          : 0;
                background-color : rgba($green, 0.3);
                opacity          : 0;
                top              : 0;
                left             : 0;
                right            : 0;
                bottom           : 0;
                font-size        : 0.8rem;
                line-height      : 1.2rem;
                transition       : opacity 800ms ease-in-out;
                display          : flex;
                flex-direction   : column;
                
                p {
                    padding : 0;
                    margin  : 0;
                }
                
                ul {
                    @include rem(padding-left, 15);
                    margin : 0;
                }
            }
        }
    }
}

body.projekte {
    // Doof aber auf die Schnelle nicht anders möglich. Dient zum Ausblenden des Banners in allen Unterkategorien
    &:not([data-id="2"]),
    &:not([data-view="itemlist"]) {
        #content-banner-top {
            display : none;
        }
    }
    
    main#content {
        .content-main-background {
            .itemListCategory {
                display         : flex;
                justify-content : center;
                align-items     : center;
                flex-direction  : column;
                height          : 100%;
                position        : relative;
                
                @include media-breakpoint-down(sm) {
                    @include rem(padding-bottom, 50);
                }
                
                .projektuebersicht-map {
                    width : 100%;
                    
                    svg {
                        width : 100%;
                        
                        .region-circle {
                            transform : scale(0);
                            
                            &.animate {
                                animation : scale 1.5s linear infinite;
                                
                                @keyframes scale {
                                    0% {
                                        transform : scale(0.5);
                                        opacity   : 1;
                                    }
                                    
                                    100% {
                                        transform : scale(2);
                                        opacity   : 0;
                                    }
                                }
                            }
                        }
                        
                        .region-group {
                            cursor : pointer;
                        }
                    }
                }
                
                .projektuebersicht-btn-container {
                    text-align : center;
                    
                    @include media-breakpoint-down(sm) {
                        @include rem(margin-top, 50);
                        width : 100%;
                    }
                    
                    @include media-breakpoint-up(md) {
                        position : absolute;
                        bottom   : 0;
                        left     : 0;
                        right    : 0;
                    }
                    
                    a.btn-projektuebersicht {
                        @include rem-multivalue(padding, 15, 25);
                        background-color : $green;
                        border           : none;
                        border-radius    : 0;
                        color            : $white;
                        width            : 100%;
                    }
                }
            }
            
            .itemListSubCategories {
                @include make-spacing(5);
                display   : flex;
                flex-wrap : wrap;
                
                div.subCategoryContainer {
                    flex-basis : 50%;
                    
                    & > a {
                        text-decoration : none;
                        display         : block;
                        height          : 100%;
                    }
                    
                    div.subCategory-item {
                        $button-height : 50;
                        @include rem(min-height, 180);
                        position       : relative;
                        height         : 100%;
                        border         : 2px solid $green;
                        border-radius  : 0;
                        transition     : border-color 0.8s, border-radius 0.8s;
                        
                        p {
                            margin : 0;
                        }
                        
                        .subCatDescr {
                            @include rem-multivalue(padding, 10);
                            height : 100%;
                            
                            div.subcategory-image,
                            div.subcategory-text {
                                position   : absolute;
                                top        : 0;
                                right      : 0;
                                left       : 0;
                                transition : opacity 0.8s, visibility 0.8s;
                            }
                            
                            div.subcategory-image {
                                @include rem(bottom, $button-height);
                                opacity         : 1;
                                visibility      : visible;
                                display         : flex;
                                justify-content : center;
                                align-items     : center;
                            }
                            
                            div.subcategory-text {
                                @include rem-multivalue(margin, 10);
                                @include rem(font-size, 14);
                                bottom           : 0;
                                background-color : $green-light;
                                opacity          : 0;
                                visibility       : hidden;
                                display          : flex;
                                align-items      : center;
                                
                                ul {
                                    @include rem(padding-left, 30);
                                    margin-bottom : 0;
                                }
                            }
                        }
                        
                        button {
                            @include rem(height, $button-height);
                            padding          : 0;
                            color            : $white;
                            border           : none;
                            border-radius    : 0;
                            background-color : $green;
                            width            : 100%;
                            opacity          : 1;
                            visibility       : visible;
                            transition       : opacity 0.8s, visibility 0.8s;
                            position         : absolute;
                            bottom           : 0;
                            left             : 0;
                        }
                    }
                    
                    %hoverStyle {
                        div.subCategory-item {
                            @include rem(border-radius, 3);
                            border-color : rgba($black, 0.2);
                            transition   : border-color 0.8s, border-radius 0.8s;
                            
                            .subCatDescr {
                                div.subcategory-image {
                                    opacity    : 0;
                                    visibility : hidden;
                                }
                                
                                div.subcategory-text {
                                    opacity    : 1;
                                    visibility : visible;
                                }
                            }
                            
                            button {
                                opacity    : 0;
                                visibility : hidden;
                                transition : opacity 0.8s, visibility 0.8s;
                            }
                        }
                    }
                    
                    a.touched {
                        @extend %hoverStyle
                    }
                    
                    a {
                        @include hover-focus-active {
                            @extend %hoverStyle
                        }
                    }
                }
            }
            
            .hwx-project-pipeline {
                .pipeline {
                    @include rem(margin-top, 100);
                    @include rem(margin-bottom, 50);
                }
            }
        }
    }
}