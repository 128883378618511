/**
Beispiel:
in dem templates/hwxtemplate/fonts Ordner liegt
WorkSans/WorkSans-Black
WorkSans/WorkSans-BlackItalic
WorkSans/WorkSans-Bold
WorkSans/WorkSans-BoldItalic
WorkSans/WorkSans-Light

und

Roboto/Roboto-Regular
Roboto/Roboto-Black
Roboto/Roboto-Light

dann:
(Achtung, WorkSans am Ende des Pfades markiert den Namen der Schrift ohne Schnitte)
"WorkSans" : (
font-path: "../fonts/WorkSans/WorkSans",
font-extensions: (ttf),
font-weights: ("ExtraLight", "Light", "Regular", "SemiBold", "Bold", "Black"),
font-italic-weights: null,
),
"Roboto" : (
                font-path: "../fonts/Roboto/Roboto",
                font-extensions: (ttf, woff, woff2),
                font-weights: ("Regular", "Bold"),
                font-italic-weights: ("Light", "Regular", "SemiBold"),
)

Weiterer Hinweis:
$template-font-family: "Work Sans" gibt die Hauptschriftart an.

weitere Schriftarten können als Variable gespeichert werden, z.B.
$font-family-second : "Roboto";
Es geht aber auch auf dem herkömmlichen Wege via css style font-family: "Roboto"
*/

$template-font-family : "Tahoma";
$template-fonts : (
    // Gleichzeitig $font-family-base
        $template-font-family : (
                font-path: "../fonts/Tahoma/Tahoma",
                font-extensions: (ttf),
                font-weights: ("Regular", "Bold"),
                font-italic-weights: null,
        )
);

$font-family-sans-serif : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base : $template-font-family, $font-family-sans-serif;