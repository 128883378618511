div#background-container {
    @include zindex(backgroundimage);
    position : fixed;
    width    : 100%;
    height   : 100%;
    top      : 0;
    left     : 0;
}

main#content {
    // if hwx-sticky-top or hwx-sticky-bottom is added as class to footer or header, margin-bottom and top is overwritten via javascript
    @include rem-multivalue(margin, $content-margin-top, $content-margin-right, $content-margin-bottom, $content-margin-left);
    @include zindex(main-content);
    background-color : $content-background-color;
    position         : relative;
    
    #content-banner-top,
    #content-banner-bottom {
        p {
            margin : 0;
        }
    }
}

#back-to-top {
    @include zindex(back-to-top);
    @include rem(top, $back-to-top-top);
    @include rem(right, $back-to-top-right);
    @include rem(bottom, $back-to-top-bottom);
    @include rem(left, $back-to-top-left);
    cursor           : pointer;
    position         : fixed;
    display          : none;
    background-color : $back-to-top-background;
    color            : $back-to-top-foreground;
}