header#header {
    @include make-sticky;
    @include zindex(header);
    @include rem-multivalue(margin, $header-margin-top, $header-margin-right, $header-margin-bottom, $header-margin-left);
    @include rem-multivalue(padding, $header-padding-top, $header-padding-right, $header-padding-bottom, $header-padding-left);
    background-color : $header-background-color;

    //div.header-navigation {
    //    nav.navbar {
    //        a.nav-link {
    //            @include header-nav-font;
    //        }
    //    }
    //}
}