:root {
    @each $color, $value in $grays {
        --gray-#{$color}: #{$value};
    }
    
    @each $breakpoint, $container-max-width in $container-max-widths {
        --container-max-width-#{$breakpoint}: #{$container-max-width};
    }
    
    --gutter-width      : #{$grid-gutter-width};
    --gutter-width-half : #{$grid-gutter-width-half};
}

html {
    position  : relative;
    height    : 100%;
    font-size : $base-font-size;
    
    body {
        height         : 100%;
        display        : flex;
        flex-direction : column;
        
        main {
            flex : 1;
        }
    }
}