h1 {
    @include rem(margin-bottom, 30);
}

h2 {
    @include rem(margin-bottom, 20);
}

.container-box-shadow {
    @include rem-multivalue(padding, $content-padding);
    @include rem(min-height, 400);
    box-shadow : 0 0 25px 25px rgba($black, 0.05);
}

body {
    color : $blue;
    
    .card-introtext,
    p {
        text-align : justify;
        hyphens    : auto;
    }
}