.hex {
    .hexagon-row {
        $hexagon-spacing : 5;
        display          : flex;
        
        @include media-breakpoint-only(xs) {
            margin-left  : 1.5vw;
            margin-right : 1.5vw;
            
            & > div {
                padding-left  : 1.5vw;
                padding-right : 1.5vw;
                margin-bottom : -10vw;
            }
        }
        
        @include media-breakpoint-only(sm) {
            & > div {
                margin-bottom : -8vw;
            }
        }
        
        @include media-breakpoint-up(sm) {
            @include make-horizontal-spacing($hexagon-spacing);
        }
        
        @include media-breakpoint-down(sm) {
            flex-wrap : wrap;
        }
        
        @include media-breakpoint-up(lg) {
            &:nth-child(2n) {
                justify-content : flex-end;
            }
        }
        
        @include media-breakpoint-only(md) {
            &:not(:first-child) {
                margin-top : -5%;
            }
        }
        
        @include media-breakpoint-only(lg) {
            &:not(:first-child) {
                @include rem(margin-top, -45);
            }
        }
        
        @include media-breakpoint-only(xl) {
            &:not(:first-child) {
                @include rem(margin-top, -55);
            }
        }
        
        &:first-child {
            .hexagon-spacer-mobile {
                background-image    : url("images/hex/grid_halb_blau.png");
                background-size     : cover;
                background-position : 0 0;
                background-repeat   : no-repeat;
                background-origin   : content-box;
            }
        }
        
        &:last-child {
            @include media-breakpoint-down(sm) {
                @include rem(margin-bottom, 50);
            }
            
            .hexagon-spacer {
                &.hexagon-spacer-mobile {
                    background-image    : url("images/hex/grid_halb_gruen.png");
                    background-size     : contain;
                    background-position : 0 0;
                    background-repeat   : no-repeat;
                    
                    &:not(.hexagon-spacer-flip) {
                        transform : scaleX(-1);
                    }
                    
                    &.hexagon-spacer-flip {
                        transform : none;
                    }
                }
            }
        }
        
        // TODO: Flexbasis für desktop fehlt
        .hexagon-spacer {
            align-items : center;
            //flex        : 1;
            //position : relative;

            img {
                //position : absolute;
                height : 100%;
                width  : 100%;
            }
            
            &.hexagon-spacer-1,
            &.hexagon-spacer-2 {
                // Es gab mal mehr container max width. Aber nach x Anpassungen sollten nur noch 2 (lg, xl) existieren. -.-
                @each $breakpoint, $container-max-width in $container-max-widths {
                    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                        $hexagon-width : 2 * $hexagon-spacing + $container-max-width - $grid-gutter-width - 2 * $content-padding;
                        @if $breakpoint == sm {
                            //@include rem(flex-basis, $hexagon-width / 5);
                        } @else {
                            // 4 ganze und eine halbe Wabe
                            // Formel:       $hexagon-width = 4x + x/2
                            //           2 * $hexagon-width = 8x + x               = 9x
                            //         2/9 * $hexagon-width = $hexagon-width / 4.5 = x
                            // Breite der halben Wabe ist dann
                            // x/2 = ($hexagon-width / 4.5) / 2 = $hexagon-width / 9;
                            @include rem(flex-basis, $hexagon-width / 9);
                        }
                    }
                }
                
                @include media-breakpoint-only(md) {
                    // -.-
                    flex-basis : 11.115%;
                }
            }
            
            &.hexagon-spacer-mobile {
                display : none;
                
                &.hexagon-spacer-flip {
                    transform : scaleX(-1);
                }
            }
            
            &.hexagon-spacer-mobile-xs {
                flex-basis : 33%;
                @include media-breakpoint-only(xs) {
                    display : flex;
                }
            }
            
            &.hexagon-spacer-mobile-sm {
                flex-basis : 20%;
                
                @include media-breakpoint-only(sm) {
                    display : flex;
                }
            }
        }
        
        .hexagon {
            cursor          : pointer;
            position        : relative;
            display         : flex;
            justify-content : center;
            align-items     : center;
            
            // Es gab mal mehr container max width. Aber nach x Anpassungen sollten nur noch 2 (lg, xl) existieren. -.-
            @each $breakpoint, $container-max-width in $container-max-widths {
                @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                    $hexagon-width : 2 * $hexagon-spacing + $container-max-width - $grid-gutter-width - 2 * $content-padding;
                    @if $breakpoint == sm {
                        @include rem(flex-basis, $hexagon-width / 2.5);
                    } @else {
                        // 4 ganze und eine halbe Wabe
                        // Formel:       $hexagon-width = 4x + x/2
                        //           2 * $hexagon-width = 8x + x               = 9x
                        //         2/9 * $hexagon-width = $hexagon-width / 4.5 = x
                        @include rem(flex-basis, $hexagon-width / 4.5);
                    }
                }
            }
            
            @include media-breakpoint-only(xs) {
                flex-basis : 67%;
            }
            
            @include media-breakpoint-only(sm) {
                flex-basis : 40%;
            }
            
            @include media-breakpoint-only(md) {
                // -.-
                flex-basis : 22.23%;
            }
            
            &.hexagon-2,
            &.hexagon-6 {
                @include media-breakpoint-down(md) {
                    margin-left : auto;
                }
            }
            
            @include hover-focus {
                .hexagon-title,
                .hexagon-main-image {
                    opacity    : 0;
                    visibility : hidden;
                }
                
                .hexagon-text,
                .hexagon-overlay-image {
                    opacity    : 1;
                    visibility : visible;
                }
            }
            
            .hexagon-title,
            .hexagon-main-image {
                opacity    : 1;
                visibility : visible;
            }
            
            .hexagon-title,
            .hexagon-main-image,
            .hexagon-overlay-image,
            .hexagon-text {
                transition : opacity 0.8s, visibility 0.8s;
            }
            
            .hexagon-title {
                @include rem(font-size, 20);
                position : absolute;
                color    : $white;
                
                @include media-breakpoint-only(xs) {
                    font-size : 4vw;
                }
                
                @include media-breakpoint-only(md) {
                    @include rem(font-size, 16);
                }
            }
            
            img {
                width : 100%;
            }
            
            .hexagon-main-image {
            }
            
            .hexagon-text,
            .hexagon-overlay-image {
                opacity    : 0;
                visibility : hidden;
                position   : absolute;
                top        : 0;
                left       : 0;
            }
            
            .hexagon-overlay-image {
                @include rem(padding-left, 5);
                @include rem(padding-right, 5);
            }
            
            .hexagon-text {
                @include rem(padding-left, 30);
                @include rem(padding-right, 30);
                display     : flex;
                align-items : center;
                height      : 100%;
                
                @include media-breakpoint-between(md, lg) {
                    @include rem(padding-left, 20);
                    @include rem(padding-right, 20);
                }
                
                p {
                    @include rem(font-size, 13);
                    text-align : center;
                    margin     : 0;
                    
                    @include media-breakpoint-only(md) {
                        font-size : 1.2vw;
                    }
                    
                    @include media-breakpoint-down(sm) {
                        font-size : 1.9vw;
                    }
                }
            }
        }
    }
}