body.unternehmen-partner,
body.umwelt-natur {
    div.partner {
        .hwx-partner-header {
            display : none;
        }
        
        div.itemList {
            .itemList-header {
                @include rem(margin-top, 60);
                @include rem(margin-bottom, 30);
            }
            
            div.card.partner-item {
                padding       : 0;
                border        : none;
                border-radius : 0;
                
                div.hwx-card-content {
                    border-radius : 0;
                    border        : 1px solid transparent;
                    transition    : border-color 0.8s, border-radius 0.8s;
                    
                    &:hover {
                        border-color  : rgba($black, 0.125);
                        border-radius : .25rem;
                    }
                    
                    .card-preview {
                        .hwx-card-img-top {
                            img {
                                height : auto;
                                width  : 100%;
                            }
                        }
                        
                        div.hwx-card-header {
                            @include rem(height, 65);
                            background-color : rgba($green, 0.7);
                            display          : flex;
                            align-items      : center;
                            
                            h4.hwx-card-title {
                                @include rem-multivalue(padding, 15);
                                width : 100%;
                                color : $white;
                            }
                        }
                    }
                    
                    .card-introtext {
                        @include rem-multivalue(margin, 15);
                        word-break            : break-word;
                        hyphens               : auto;
                        hyphenate-limit-chars : auto;
                        hyphenate-limit-lines : 2;
                    }
                }
            }
        }
    }
}

body.unternehmen-partner {
    div.partner {
        div.itemList {
            div.card.partner-item {
                div.hwx-card-content {
                    border     : 1px solid rgba($black, 0.125);
                    transition : border-radius 0.8s;
                    
                    &:hover {
                        border-radius : .25rem;
                    }
                    
                    .card-preview {
                        .hwx-card-img-top {
                            @include rem-multivalue(padding, 20);
                            height : 100%;
                        }
                    }
                }
            }
        }
    }
}

div.partner {
    div.itemListCategoriesBlock {
        @include rem(margin-bottom, 50);
        
        div.itemListCategory {
            div.card-body {
                padding : 0;
                
                h3 {
                    font-size : 1.3rem;
                    color     : $green;
                }
            }
            
            h1 {
                font-size  : 2rem;
                text-align : center;
            }
            
            div.card {
                border : none;
            }
        }
    }
    
    div.itemList {
        div.card.partner-item {
            @include rem-multivalue(padding, 15);
            
            div.hwx-card-content {
                position   : relative;
                min-height : 225px;
                
                &:hover {
                    cursor : pointer;
                    
                    .card-preview {
                        opacity    : 0;
                        transition : opacity 800ms ease-in-out;
                    }
                    
                    .card-introtext {
                        opacity    : 1;
                        transition : opacity 800ms ease-in-out;
                    }
                    
                }
                
                .card-preview {
                    position         : absolute;
                    z-index          : 1;
                    background-color : #ffffff;
                    top              : 0;
                    left             : 0;
                    right            : 0;
                    bottom           : 0;
                    opacity          : 1;
                    transition       : opacity 800ms ease-in-out;
                    display          : flex;
                    flex-direction   : column;
                    justify-content  : center;
                    overflow         : hidden;
                    
                    div.hwx-card-img-top {
                        text-align : center;
                        
                        img {
                            @include rem(height, 60);
                        }
                    }
                    
                    div.hwx-card-header {
                        position : absolute;
                        left     : 0;
                        right    : 0;
                        bottom   : 0;
                        
                        h4.hwx-card-title {
                            color      : $green;
                            font-size  : 0.9rem;
                            text-align : center;
                            margin     : 0;
                        }
                    }
                }
                
                .card-introtext {
                    @include rem-multivalue(padding, 15);
                    
                    position         : absolute;
                    z-index          : 0;
                    background-color : rgba($green, 0.3);
                    opacity          : 0;
                    top              : 0;
                    left             : 0;
                    right            : 0;
                    bottom           : 0;
                    font-size        : 0.8rem;
                    line-height      : 1.2rem;
                    transition       : opacity 800ms ease-in-out;
                    display          : flex;
                    flex-direction   : column;
                    overflow         : hidden;
                    
                    p {
                        padding : 0;
                        margin  : 0;
                    }
                    
                    ul {
                        @include rem(padding-left, 15);
                        margin : 0;
                    }
                }
            }
        }
    }
}